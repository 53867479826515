import React from "react";

export const Team = (props) => {

  const imgStyle = {
    width: '500px', // Set a fixed width
    height: '250px', // Set a fixed height
    objectFit: 'cover', // This will crop the images to fit
  };
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Gallery</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4 col-sm-4 team">
                  <div className="thumbnail">
                    {" "}
                    <img  style={imgStyle} src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      {/* <h4>{d.name}</h4> */}
                      {/* <p>{d.job}</p> */}
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
