import React from "react";

export const ImageService = ({ title, largeImage, smallImage }) => {

    const frameStyle = {
        width: '100%', // Set your desired width
        height: '30%', // Set your desired height
        // border: '5px solid #ccc', // Border styling
        borderRadius: '8px', // Rounded corners, if desired
        overflow: 'hidden', // Hides overflow from the image
        display: 'flex', // Center image if needed
        alignItems: 'center', // Center image vertically
        justifyContent: 'center', // Center image horizontally
      };
    
      const imageStyle = {
        width: '100%', // Make image responsive
        height: '100%', // Maintain aspect ratio
        display: 'block', // Remove bottom space
      };

  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        {" "}
        <a href={largeImage} title={title} data-lightbox-gallery="gallery1">
         
          
            <div style={frameStyle}>
            <div className="hover-text">
            <p style={{textAlign: 'justify', marginLeft: '40px', marginRight:'40px', marginTop: '-90px'}}>{title}</p>
          </div>
                <img 
                    className="img-responsive"
                    src={smallImage} 
                    alt={title} 
                    style={imageStyle} 
                />
                </div>

        </a>{" "}
      </div>
    </div>
  );
};
